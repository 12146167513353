<template>
  <div class="modal-user-info">
    <esmp-modal
      v-model="show"
      width="560"
      title="Информация о сотруднике"
      class-name="modal-user-info"
    >
      <div class="modal-user-info__list">
        <div class="modal-user-info__item" v-if="currentUserInfo.fullName">
          <div class="modal-user-info__label">
            ФИО
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.fullName }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.appointmentName">
          <div class="modal-user-info__label">
            Должность
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.appointmentName }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.corpStructure">
          <div class="modal-user-info__label">
            Оргструктура
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.corpStructure }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.location">
          <div class="modal-user-info__label">
            Местоположение
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.location }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.room">
          <div class="modal-user-info__label">
            Помещение
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.room }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.managerFullName">
          <div class="modal-user-info__label">
            Руководитель
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.managerFullName }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.workPhone">
          <div class="modal-user-info__label">
            Рабочий телефон
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.workPhone }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.extPhone">
          <div class="modal-user-info__label">
            Внутренний телефон
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.extPhone }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.mobilePhone">
          <div class="modal-user-info__label">
            Мобильный телефон
          </div>
          <div class="modal-user-info__value">
            {{ currentUserInfo.mobilePhone }}
          </div>
        </div>
        <div class="modal-user-info__item" v-if="currentUserInfo.email">
          <div class="modal-user-info__label">
            E-mail
          </div>
          <div class="modal-user-info__value">
            <esmp-link :to="`mailto:${currentUserInfo.email}`">
              {{ currentUserInfo.email }}
            </esmp-link>
          </div>
        </div>
      </div>
      <div slot="footer">
        <esmp-button @click="show = false">
          Закрыть
        </esmp-button>
      </div>
    </esmp-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalUserInfo',
  props: {
    currentUserInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
};
</script>

<style lang="scss">
.modal-user-info {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 16px;
  }

  &__label {
    width: 118px;
    flex-shrink: 0;
    color: $color-grayscale-60;
    margin-right: 24px;
  }

  &__value {
    width: calc(100% - 118px);

    .esmp-link {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
